/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


@import "../theme.scss";
// @import '~bootstrap/scss/bootstrap';
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// * {
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }

*{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

html{
  width: 100% !important;
  height: 100%;
}

body {
    width: 100% !important;

    margin: 0;
    height: 100%;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    min-height: 100vh;
    // background-image: url("https://storage.googleapis.com/rendiapps_bucket/escopremia/Mueve%20tu%20pasi%C3%B3n/background.png");
  // zoom: 85%;
}



// mat-sidenav {
//   width: 20%;
// }

table {
  width: 100%;
}

.container {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 10px;
  // background-image: url("https://storage.googleapis.com/rendiapps_bucket/escopremia/Mueve%20tu%20pasi%C3%B3n/background.png") !important;
}
form {
  margin-top: 10px !important;
}

.spacer{
  flex: 1 1 auto;
}

.mdc-button.label-success
{
  background-color: rgb(82, 189, 82);
  color: #fff !important;
  border-radius: 25px;
}
// .label-success
// {
//   background-color: rgb(82, 189, 82) !important;
//   color: #fff !important;
//   border-radius: 25px !important;
// }

.mdc-button.label-red
{
  background-color: #FF4D22;
  color: #fff !important;
  border-radius: 25px;
  font-size: small;
  line-height: 200%;
}

.mdc-button.label-info
{
  background-color: #228AFF;
  color: #fff !important;
  border-radius: 25px;
  // min-width: 1%;
  font-size: small;
  line-height: 200%;
}

.mdc-button.label-primary
{
  background-color: $primary;
  color: #fff;
  border-radius: 25px;
  font-size: small;
  line-height: 200%;
}

.mdc-button.label-accent
{
  background-color: $accent;
  color: #fff;
  border-radius: 25px;
  font-size: small;
  line-height: 200%;
}

.mdc-button.mat-success,
.mat-icon-button.mat-success
//.mat-stroked-button.mat-success
{
    color: #155724;
}

.mdc-button.mat-info,
.mat-icon-button.mat-info
//.mat-stroked-button.mat-success
{
  color: #228AFF;
}

.mat-icon {
  transform: scale(.7) !important;
}

.mat-cell {
  font-size: 90% !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
  // color: $primary !important;
  color: rgb(78, 74, 74) !important;
}

//input,
// textarea,
h1{
  color: #4e4a4a !important;
   font-size: smaller !important;
}

// .mat-form-field-wrapper{
//   transform: scale(.9) !important;
// }



.swal2-confirm {
  background: $primary !important;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar {
    // display: none;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.img-avatar {
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
  object-position: center;
  width: 50px;
}

.btn-success{
  background-color: $apoyoPrimario !important;
  color: white !important;
}
@media (max-width:768px){
  .ocultar{
    display: none;
  }
 }

//  .full-screen-modal .mat-dialog-container {
  // padding: 1em !important;
  // width: 100% !important;
  // height: 1000px !important;
// }

.full-screen-modal .mat-dialog-container {
  max-width: none !important;
}


/*NO QUITAR!! Esto soluciona bug de compatibilidad datetimepicker vs datePicker nativo(TouchUI)*/

.mat-datepicker-dialog .mat-datepicker-content-touch .mat-calendar{
  width: 100% !important;
  height: auto !important;
}

td.mat-calendar-body-cell{
  position: relative !important;
    height: 0 !important;
    line-height: 0 !important;
    text-align: center !important;
    outline: none !important;
    cursor: pointer !important;
}

button.mat-calendar-body-cell{
  -webkit-user-select: none !important;
      user-select: none !important;
      cursor: pointer !important;
      outline: none !important;
      border: none !important;
      -webkit-tap-highlight-color: transparent !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      background: none !important;
      text-align: center !important;
      outline: none !important;
      font-family: inherit !important;
      margin: 0 !important;
}

.mat-mini-fab,
.mat-raised-button + .mat-primary{
  color: #fff !important;
}
// .divDateTime > .mat-calendar-body-cell {
//   position: relative !important;
//   height: 0 !important;
//   line-height: 0 !important;
//   text-align: center !important;
//   outline: none !important;
//   cursor: pointer !important;
// }
// .mat-datepicker-dialog .mat-datepicker-content-touch .mat-calendar{
//   width: 100% !important;
//   height: auto !important;
// }

// .mat-calendar {
//   display: block !important;
// }

// .mat-calendar-content {
//   padding: 0 8px 8px 8px;
//   outline: none;
// }
// mat-datepicker-content{

//   :not(ngx-mat-datetime-content){
//     .mat-datepicker-content-touch .mat-calendar-body-cell{
//       -webkit-user-select: none !important;
//       user-select: none !important;
//       cursor: pointer !important;
//       outline: none !important;
//       border: none !important;
//       -webkit-tap-highlight-color: transparent !important;
//       position: absolute !important;
//       top: 0 !important;
//       left: 0 !important;
//       width: 100% !important;
//       height: 100% !important;
//       background: none !important;
//       text-align: center !important;
//       outline: none !important;
//       font-family: inherit !important;
//       margin: 0 !important;
//     }
//   }
// }

// mat-calendar-body-active

// class="mat-calendar-body-cell mat-calendar-body-active"

// ngx-mat-datetime-content{
//   .mat-calendar-body-cell {
//     position: relative !important;
//     height: 0 !important;
//     line-height: 0 !important;
//     text-align: center !important;
//     outline: none !important;
//     cursor: pointer !important;
//   }
// }

// .mat-datepicker-dialog .mat-datepicker-content-touch .mat-calendar-body-cell{
//     -webkit-user-select: none !important;
//     user-select: none !important;
//     cursor: pointer !important;
//     outline: none !important;
//     border: none !important;
//     -webkit-tap-highlight-color: transparent !important;
//     position: absolute !important;
//     top: 0 !important;
//     left: 0 !important;
//     width: 100% !important;
//     height: 100% !important;
//     background: none !important;
//     text-align: center !important;
//     outline: none !important;
//     font-family: inherit !important;
//     margin: 0 !important;
// }

/**/

// :not(.divDateTime) > .mat-calendar-body-cell {
//   -webkit-user-select: none !important;
//   user-select: none !important;
//   cursor: pointer !important;
//   outline: none !important;
//   border: none !important;
//   -webkit-tap-highlight-color: transparent !important;
//   position: absolute !important;
//   top: 0 !important;
//   left: 0 !important;
//   width: 100% !important;
//   height: 100% !important;
//   background: none !important;
//   text-align: center !important;
//   outline: none !important;
//   font-family: inherit !important;
//   margin: 0 !important;
// }

/*FIN! NO QUITAR!! Esto soluciona bug de compatibilidad datetimepicker vs datePicker nativo(TouchUI)*/

.btn-crear {
  display:block;
  width:70px;
  height:70px;
  position: fixed;
  right:20px;
  bottom:20px;
  border-radius:50%;
  line-height:80px;
  text-align:center;
  z-index:999;
  color: white;
}
