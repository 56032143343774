@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat-core();

$primario: (
    50 : #e2eef3,
    100 : #b8d5e0,
    200 : #88b9cc,
    300 : #589db7,
    400 : #3588a7,
    500 : #117398,
    600 : #0f6b90,
    700 : #0c6085,
    800 : #0a567b,
    900 : #05436a,
    A100 : #9ad3ff,
    A200 : #67bcff,
    A400 : #34a6ff,
    A700 : #1a9bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )


);
$secundario:(
    50 : #e9f7f6,
    100 : #c8eae7,
    200 : #a3dcd8,
    300 : #7ecec8,
    400 : #62c4bc,
    500 : #46b9b0,
    600 : #3fb2a9,
    700 : #37aaa0,
    800 : #2fa297,
    900 : #209387,
    A100 : #cffffa,
    A200 : #9cfff4,
    A400 : #69ffee,
    A700 : #4fffeb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )


);

$hexApoyoPrimario: (
    50 : #fdfcfa,
    100 : #fbf8f3,
    200 : #f9f4eb,
    300 : #f6f0e3,
    400 : #f4ecdd,
    500 : #f2e9d7,
    600 : #f0e6d3,
    700 : #eee3cd,
    800 : #ecdfc7,
    900 : #e8d9be,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$hexApoyoSecundario: (
    50 : #eeeded,
    100 : #d4d3d2,
    200 : #b8b6b5,
    300 : #9b9997,
    400 : #858380,
    500 : #706d6a,
    600 : #686562,
    700 : #5d5a57,
    800 : #53504d,
    900 : #413e3c,
    A100 : #f8c5a3,
    A200 : #f4a774,
    A400 : #ff8636,
    A700 : #ff771c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



$my-app-primary:  mat-palette($primario);
$my-app-accent:  mat-palette($secundario);
$my-app-warn:    mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

$my-app-apoyoPrimario:  mat-palette($hexApoyoPrimario);
$my-app-apoyoSecundario:  mat-palette($hexApoyoSecundario);

@include angular-material-theme($my-app-theme);

// Create your Sass color vars (will be available in all the project)
$primary: mat-color($my-app-primary);
$accent: mat-color($my-app-accent);
$warn: mat-color($my-app-warn);

$apoyoPrimario: mat-color($my-app-apoyoPrimario);
$apoyoSecundario: mat-color($my-app-apoyoSecundario);

.alternate-theme {
  $alternate-primary: mat-palette($mat-light-blue);
  $alternate-accent:  mat-palette($mat-yellow, 400);
  $alternate-theme: mat-light-theme($alternate-primary, $alternate-accent);
  @include angular-material-theme($alternate-theme);
}

